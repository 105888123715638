<template>
    <div v-if="isOpen" class="modal fade show" aria-labelledby="addNewCardTitle" style="display: block; background: #1c1d1c33">
        <div class="modal-dialog modal-dialog-centered">
            <div class="modal-content">
                <div class="row">
                    <div class="col-12">
                        <div class="d-flex justify-content-between px-1 my-1 align-item-center">
                            <h1 class="mb-0">{{ editMode ? "Edit" : "Add New"}} Role</h1>
                            <button
                                @click="toggleModal"
                                type="button"
                                class="btn-close"
                                data-bs-dismiss="modal"
                                aria-label="Close"
                            ></button>
                        </div>
                    </div>
                </div>
                <hr class="mt-0">

                <div class="pb-2">
                    <!-- form -->
                    <vForm @submit="submit" :validation-schema="schema">
                        <div class="row gy-2 px-2">
                            <div class="col-12">
                                <label class="form-label" for="chartName">Role Name</label>
                                <div class="input-group">
                                    <vField
                                        v-model="formData.label"
                                        type="text"
                                        name="label"
                                        class="form-control add-credit-card-mask"
                                        placeholder="Ex: CEO"
                                    />
                                    <ErrorMessage name="label" class="invalid-feedback d-block qcont"/>
                                </div>
                            </div>

                            <div class="col-12">
                                <label class="form-label" for="chartName">Status</label>
                                <v-select
                                        class="w-100"
                                        placeholder="Select Status"
                                        v-model="formData.status"
                                        :options="status"
                                        label="name"
                                        :reduce="name => name.key"
                                    />
                              <ErrorMessage name="status" class="invalid-feedback d-block qcont"/>
                            </div>
                        </div>

                        <div class="col-12 text-center">
                            <hr class="mt-2">
                            <button :disabled="loading" type="submit" class="btn btn-primary me-1 mt-1 waves-effect waves-float waves-light">
                                <div v-if="loading" class="spinner-border spinner-border-sm text-light" role="status">
                                    <span class="visually-hidden">Loading...</span>
                                </div>
                              {{ editMode ? "Update" : "Submit"}}
                            </button>
                            <button @click.prevent="toggleModal" class="btn btn-outline-secondary mt-1 waves-effect">
                                Cancel
                            </button>
                        </div>
                    </vForm>
                </div>
            </div>
        </div>
    </div>
</template>

<script>
export default {
    name: 'RoleInviteModal',

    data: () => ({
        isOpen: false,
        loading: false,
        schema: {
          label: 'required|min:3|max:100',
        },
      status: [
        {
          key: "active",
          name: "Active",
        },
        {
          key: "inactive",
          name: "Inactive",
        }
      ]
    }),


    methods: {
        submit () {
            this.$emit(this.eventName)
        },
        toggleModal () {
            this.isOpen = !this.isOpen
        },
        onUploadOwnerPhoto(event) {
            this.formData.owner_photo = event.target.files[0]
        },
        onUploadBusinessPhoto(event) {
            this.formData.business_photo = event.target.files[0]
        }
    },

    props: {
        formData: Object,
        roles: Array,
        eventName: String,
        editMode: Boolean
    }
}
</script>
