<template>
    <div class="table-responsive table-striped my-1">
        <table class="table table-bordered">
          <thead>
          <tr>
            <th>#</th>
            <th>ID</th>
            <th>Role</th>
            <th>Status</th>
            <th>Action</th>
          </tr>
          </thead>
            <tbody>
                <template v-if="roles && roles.length">
                    <tr v-for="(role, index) in roles" :key="role.id">
                        <td>{{ ++index }}</td>
                        <td>{{ role.id }}</td>
                        <td>{{ role.label }}</td>
                        <td class="text-capitalize">{{ role.status }}</td>
                      <td>
                        <slot :role="role"></slot>
                      </td>
                    </tr>
                </template>
            </tbody>
        </table>
    </div>
</template>

<script>

export default {
    name: 'RoleListTable',
    props: ['roles']
}
</script>
