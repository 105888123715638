<template>
  <div>
    <!-- role section -->
    <div class="card shadow">
      <div class="card-body  p-sm-0">
        <div class="d-flex justify-content-between align-items-baseline p-1">
          <h4 class="card-title mb-0 ml-0">Role List</h4>
          <div class="d-flex align-items-baseline">
            <router-link
                class="card-title"
                :to="{ name: 'company-settings', params: { companyId:$route.params.companyId, moduleId:$route.params.moduleId }}"
            >Users
            </router-link>
            <button @click="openRoleAddModal" class="btn btn-text">Add new Role</button>
          </div>
        </div>
        <hr>

        <div class="px-2 mb-5 pt-1">
          <RoleListTable :roles="roles" v-slot="{role}">
            <router-link
                :to="{ name: 'role-permission', params: { companyId:$route.params.companyId, moduleId:$route.params.moduleId, roleId:role.id }}"
                class="btn btn-icon btn-icon rounded-circle btn-flat-success waves-effect">
              <img width="16" src="/app-assets/images/icons/plus-solid.svg" alt="">
            </router-link>
            <button
                v-if="role.company_id"
                @click="openRoleEditModal(role)"
                type="button"
                class="btn btn-icon btn-icon rounded-circle btn-flat-success waves-effect">
              <img width="16" src="/app-assets/images/icons/pencil-alt-solid.svg" alt="">
            </button>
            <button
                @click="onDelete(role.id)"
                type="button"
                class="d-none btn btn-icon btn-icon rounded-circle btn-flat-success waves-effect">
              <img width="20" src="/app-assets/images/icons/trash.svg" alt="">
            </button>
          </RoleListTable>
        </div>
      </div>
    </div>
    <!-- role section -->
    <RoleInviteModal
        @onSendRequest="handleInvitation"
        @onUpdateRoleRequest="updateRoleRequest"
        :editMode="editMode"
        :eventName="eventName"
        :formData="inviteData"
        :roles="roles"
        ref="role"
    />
  </div>
</template>

<script>
import {computed, inject} from "vue";
import {useStore} from "vuex";
import handleRole from "@/services/modules/role";
import RoleListTable from "@/components/molecule/company/role/RoleListTable";
import RoleInviteModal from '@/components/molecule/company/role/RoleInviteModal'

export default {
  name: 'CompanyRole',

  components: {
    RoleListTable,
    RoleInviteModal
  },
  data: () => ({
    currentEditRole: '',
    editMode: false,
    eventName: '',
    roles: [],
    inviteData: {
      label: '',
      status: 'active'
    }
  }),
  computed: {
    companyId() {
      return this.$route.params.companyId
    }
  },
  methods: {
    openRoleAddModal() {
      this.eventName = 'onSendRequest'
      this.editMode = false
      this.resetRoleAddForm()
      this.$refs.role.toggleModal()
    },
    resetRoleAddForm() {
      this.inviteData = {
        label: '',
        company_id: this.companyId,
        status: 'active'
      }
    },
    handleInvitation() {
      this.$refs.role.loading = true
      this.sendRoleRequest(this.inviteData)
          .then(res => {
            if (!res.status) this.showError(res.message)
            if (res.status) {
              this.showSuccess(res.message)
              this.$refs.role.toggleModal()
              this.getRoleList()
            }
            this.$refs.role.loading = false
          })
          .catch(err => {
            this.$refs.role.loading = false
          })
    },

    updateRoleRequest() {
      const {role_id, status, label} = this.inviteData
      this.updateRoleRoleRequest({
        company_id: this.companyId,
        company_role_id: role_id,
        status: status,
        label: label,
      }, this.currentEditRole)
          .then(res => {
            if (!res.status) this.showError(res.message)
            if (res.status) {
              this.showSuccess(res.message)
              this.$refs.role.toggleModal()
              this.resetRoleAddForm()
              this.getRoleList()
            }
          })
          .catch(err => {
          })
    },
    onDelete(id) {
      this.loading = true
      this.deleteRole(id, this.companyId)
          .then(res => {
            this.loading = false
            if(res.status) {
              this.showSuccess('Delete success')
              this.getRoleList()
            }
          })
          .catch(err => {
            this.showError('Something went wrong!')
          })
    },
    getRoleList() {
      this.roleList(this.companyId)
          .then(res => {
            if (res.status) this.roles = res.data
          })
    },
    openRoleEditModal(role) {
      this.eventName = 'onUpdateRoleRequest'
      this.editMode = true
      let inviteData = this.inviteData
      const {id, label, status, name} = role
      this.currentEditRole = id
      inviteData.status = status
      inviteData.label = label
      this.$refs.role.toggleModal()
    },
  },
  setup() {
    const showError = inject('showError');
    const showSuccess = inject('showSuccess');
    const store = useStore()

    const {roleList, sendRoleRequest, updateRoleRoleRequest, deleteRole} = handleRole()

    return {
      company: computed(() => store.state.company.company),
      showError,
      showSuccess,
      roleList,
      sendRoleRequest,
      updateRoleRoleRequest,
      deleteRole
    }
  },
  mounted() {
    this.getRoleList()
  }
}
</script>
